import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import type {
	TextBoxChangeEvent,
	TextBoxHandle,
} from "@progress/kendo-react-inputs";
import { debounce } from "es-toolkit";
import NProgress from "nprogress";
import { useEffect, useRef, useState } from "react";
import { LOCATIONS_GRID_COLUMNS } from "../../../../common/models/src/lib/constants/grid-column.constants";
import { LOCATIONS_PAGE_SIZE } from "../../../../common/models/src/lib/constants/location.constants";
import {
	ModalSize,
	ModalType,
} from "../../../../common/models/src/lib/enums/modal.enums";
import type { ILocation } from "../../../../common/models/src/lib/interfaces/location.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumGridHeader } from "../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumSearchInput } from "../../../../common/ui/src/lib/components/celerum-input/celerum-input.component";
import { CelerumModal } from "../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { buildFilterQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";
import {
	clearLocationsAction,
	deleteLocationAction,
	fetchLocationsAction,
} from "../../../data-access/src/lib/locations.slice";
import { LocationForm } from "./components/location-form/location-form.component";

export const LocationsFeature = () => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { data, total, loading } = useAppSelector((state) => state.locations);

	const [filters, setFilters] = useState<string>("");
	const [showModal, setShowModal] = useState<{
		createOrUpdate: boolean;
		delete: boolean;
	}>({
		createOrUpdate: false,
		delete: false,
	});
	const [sort, setSort] = useState<string>("");
	const [page, setPage] = useState<Page>({
		skip: 0,
		take: LOCATIONS_PAGE_SIZE,
	});
	const [selectedLocation, setSelectedLocation] = useState<ILocation>();
	const [searchFilter, setSearchFilter] = useState<string>("");

	const searchInputRef = useRef<TextBoxHandle>(null);

	const openCreateModal = () => {
		setShowModal({ ...showModal, createOrUpdate: true });
	};

	const openUpdateModal = (location: ILocation) => {
		setSelectedLocation(location);
		setShowModal({ ...showModal, createOrUpdate: true });
	};

	const openDeleteModal = (location: ILocation) => {
		setSelectedLocation(location);
		setShowModal({ ...showModal, delete: true });
	};

	const closeModal = () => {
		setShowModal({ createOrUpdate: false, delete: false });
		setSelectedLocation(undefined);
	};

	const handleDeleteLocation = () => {
		if (!selectedLocation) return;

		dispatchWithNotifications({
			action: deleteLocationAction,
			payload: selectedLocation.id,
			successMessage: `Location with address ${selectedLocation.address} was deleted successfully.`,
			errorMessage: `Could not delete location with address ${selectedLocation.address}.`,
		});
		setSelectedLocation(undefined);
	};

	const handleSearchChange = debounce((e: TextBoxChangeEvent) => {
		setSearchFilter(e.value?.toString() || "");
	}, 300);

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < data.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (data[i]?.id === -1) {
				if (loading) {
					return;
				}

				const page = Math.ceil(skip / LOCATIONS_PAGE_SIZE) + 1;
				dispatchWithNotifications({
					action: fetchLocationsAction,
					payload: { page, pageSize: LOCATIONS_PAGE_SIZE, sort, filters },
					errorMessage: "Could not fetch jobs.",
				});
				break;
			}
		}
	};

	const requestSortedData = (sort: string) => {
		setSort(sort);
		/** Always clear existing data and start fetching again
		 * from page 1.
		 */
		dispatch(clearLocationsAction());
		dispatchWithNotifications({
			action: fetchLocationsAction,
			payload: { page: 1, pageSize: LOCATIONS_PAGE_SIZE, sort },
			errorMessage: "Could not sort subcontractors.",
		});
	};

	useEffect(() => {
		const filters = buildFilterQueryString(
			searchFilter,
			["name", "customer.name", "address", "notes"],
			[],
		);

		setFilters(filters);

		if (searchFilter) {
			dispatchWithNotifications({
				action: fetchLocationsAction,
				payload: { filters },
				errorMessage: "Could not fetch locations!",
			});
		} else {
			dispatchWithNotifications({
				action: fetchLocationsAction,
				errorMessage: "Could not fetch locations!",
			});
		}

		return () => {
			dispatch(clearLocationsAction());
		};
	}, [dispatch, dispatchWithNotifications, searchFilter]);

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	return (
		<>
			<CelerumGridHeader
				title="Locations"
				numberOfItems={total}
				addButtonName="Add Location"
				addButtonDisabled={loading}
				handleOpenAddModal={openCreateModal}
			>
				<CelerumSearchInput
					inputRef={searchInputRef}
					setSearchValue={(value: string) => setSearchFilter(value)}
					onChange={(event: TextBoxChangeEvent) => handleSearchChange(event)}
				/>
			</CelerumGridHeader>
			<CelerumGrid
				page={page}
				setPage={setPage}
				columns={LOCATIONS_GRID_COLUMNS}
				data={data}
				total={total}
				handleUpdate={openUpdateModal}
				handleDelete={openDeleteModal}
				requestDataIfNeeded={requestDataIfNeeded}
				requestSortedData={requestSortedData}
			/>
			<CelerumModal
				title={selectedLocation ? selectedLocation.name : "Add New Location"}
				width={ModalSize.Medium}
				toggleDialog={closeModal}
				visible={showModal.createOrUpdate}
			>
				<LocationForm
					onClose={closeModal}
					selectedLocation={selectedLocation}
				/>
			</CelerumModal>
			<CelerumConfirmModal
				type={ModalType.Delete}
				subtitle="The location will be completely removed and cannot be recovered."
				entityName="location"
				entityProperty="named"
				entityValue={selectedLocation?.name}
				isOpen={showModal.delete}
				handleSubmit={handleDeleteLocation}
				handleClose={closeModal}
			/>
		</>
	);
};
