import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DetailsGoods } from "../../DetailsGoods";
import { DetailsLegs } from "../../DetailsLegs";
import { useAuditDialog } from "../../dialogs/useAuditDialog";
import { useDocumentsFormDialog } from "../../dialogs/useDocumentsFormDialog";
import { useGoodsSelectionDialog } from "../../dialogs/useGoodsSelectionDialog";
import { useLegFormDialog } from "../../dialogs/useLegFormDialog";
import { useLegNoteDialog } from "../../dialogs/useLegNoteDialog";
import { useLoadFormDialog } from "../../dialogs/useLoadFormDialog";
import { useLocationFormDialog } from "../../dialogs/useLocationFormDialog";
import { useMultiEditDialog } from "../../dialogs/useMultiEditDialog";
import { DGrid } from "../../display/DGrid";
import {
	AttachmentUsageType,
	TableNameType,
	jobApi,
	toasted,
	useLegStatus,
} from "../../helpers";
import { LoadDetailsInformation } from "./LoadDetailsInformation";
import { LoadDetailsTitle } from "./LoadDetailsTitle";

export const LoadDetailsPage2 = () => {
	const params = useParams();
	const [openLegFormDialog, legFormDialog] = useLegFormDialog();
	const [openLegNoteFormDialog, CollectionNoteFormDialog] = useLegNoteDialog();
	const [openMultiEditDialog, multiEditDialog] = useMultiEditDialog();
	const [openAuditDialog, auditDialog] = useAuditDialog(TableNameType.Job);
	const [showDocumentsForm, documentsFormDialog] = useDocumentsFormDialog();
	const [showLocationForm, locationFormDialog] = useLocationFormDialog();
	const [showLoadForm, loadFormDialog] = useLoadFormDialog(showLocationForm);
	const [showGoodsSelectionDialog, goodsSelectionDialog] =
		useGoodsSelectionDialog();
	const loadId = Number(params.id);
	const response = useQuery({
		queryKey: ["load-details", loadId],
		queryFn: () => jobApi.bff.bffLoadDetailsDetail(loadId),
	});
	const data = useMemo(() => response.data?.data, [response.data?.data]);
	const navigate = useNavigate();
	const { getLegColor } = useLegStatus();
	if (!data) return <div>Loading...</div>;
	return (
		<DGrid rows="50px 1fr">
			<title>
				{data
					? `Load ${data.id} - ${data.uniqueId}`
					: "Load details, loading..."}
			</title>
			{auditDialog}
			{multiEditDialog}
			{legFormDialog}
			{CollectionNoteFormDialog}
			{documentsFormDialog}
			{locationFormDialog}
			{loadFormDialog}
			{goodsSelectionDialog}
			<LoadDetailsTitle
				data={data}
				onGoBackClick={() => navigate("/loads2")}
				onActionClick={(x) => {
					switch (x) {
						case "Audit":
							openAuditDialog(loadId, TableNameType.Load);
							break;
						case "Resume":
							toasted(
								jobApi.load
									.loadChangeStatusResumeCreate(loadId)
									.then(() => response.refetch()),
								"Resuming load",
							);
							break;
						case "Multi Edit":
							openMultiEditDialog({
								loadId,
								onSubmitClicked: () => response.refetch(),
							});
							break;
						case "Pause":
							toasted(
								jobApi.load
									.loadChangeStatusPauseCreate(loadId)
									.then(() => response.refetch()),
								"Pausing load",
							);
							break;
						case "Delete":
							toasted(
								jobApi.load
									.loadChangeStatusCancelCreate(loadId)
									.then(() => navigate("/loads2")),
								"Deleting load",
							);
							break;
						case "Generate Manifest":
							toasted(
								jobApi.load
									.loadReportManifestDetail(loadId, {
										format: "blob",
									})
									.then((x) =>
										window.open(URL.createObjectURL(x.data), "_blank"),
									),
								"Generating manifest",
							);
							break;
						case "Generate All Documents":
							toasted(
								jobApi.load
									.loadReportDetail(loadId, 1, { format: "blob" })
									.then((x) =>
										window.open(URL.createObjectURL(x.data), "_blank"),
									),
								"Generating all documents",
							);
							break;
						case "Generate Manifest and CMR":
							toasted(
								jobApi.load
									.loadReportDetail(loadId, 3, { format: "blob" })
									.then((x) =>
										window.open(URL.createObjectURL(x.data), "_blank"),
									),
								"Generating manifest and CMRs",
							);
							break;
						case "Generate Manifest and Delivery Tickets":
							toasted(
								jobApi.load
									.loadReportDetail(loadId, 2, { format: "blob" })
									.then((x) =>
										window.open(URL.createObjectURL(x.data), "_blank"),
									),
								"Generating manifest and delivery tickets",
							);
							break;
					}
				}}
			/>
			<DGrid columns="1fr 1.5fr">
				<DGrid rows="1fr 1fr">
					<LoadDetailsInformation
						data={data}
						onEditClick={() =>
							showLoadForm({
								id: loadId,
								onSubmit: () => {
									showLoadForm();
									response.refetch();
								},
							})
						}
						onViewDocumentsClick={() =>
							showDocumentsForm({
								entityId: loadId,
								isPod: false,
								usage: AttachmentUsageType.Load,
								onChange: () => response.refetch(),
							})
						}
					/>
					<DetailsGoods
						data={data}
						getLegStatusColor={(x) => getLegColor(x) ?? "black"}
						onAddGoodClicked={() => {
							showGoodsSelectionDialog({
								onGoodsSelected: (goodIds) =>
									toasted(
										jobApi.goods
											.goodsLoadCreate(loadId, {
												goods: goodIds.map((x) => ({
													goodsId: x,
													haveDeliveryOnRoute: false,
													havePickupOnRoute: false,
												})),
											})
											.then(() => response.refetch())
											.then(() => showGoodsSelectionDialog()),
										"Adding goods",
									),
							});
						}}
						onRemoveGoodClicked={(id) => {
							return toasted(
								jobApi.goods
									.goodsLoadDelete(id, loadId)
									.then(() => response.refetch()),
								"Removing good",
							);
						}}
						onGenerateCMRClicked={(id) =>
							toasted(
								jobApi.goods
									.goodsReportCmrDetail(id, {
										format: "blob",
									})
									.then((x) =>
										window.open(URL.createObjectURL(x.data), "_blank"),
									),
								"Generating CMR",
							)
						}
						onGenerateCargoLabelClicked={(id) =>
							toasted(
								jobApi.goods
									.goodsReportCargoLabelDetail(id, {
										format: "blob",
									})
									.then((x) =>
										window.open(URL.createObjectURL(x.data), "_blank"),
									),
								"Generating cargo label",
							)
						}
					/>
				</DGrid>
				<DetailsLegs
					data={data}
					onAddLegClicked={() =>
						openLegFormDialog({
							loadId,
							onSubmit: () => {
								response.refetch();
								openLegFormDialog();
							},
						})
					}
					onEditLegClicked={(id) =>
						openLegFormDialog({
							id,
							onSubmit: () => {
								response.refetch();
								openLegFormDialog();
							},
						})
					}
					onRemoveLegClicked={(id) =>
						toasted(
							jobApi.leg.deleteLeg(id).then(() => response.refetch()),
							"Deleting leg",
						)
					}
					onViewDocumentsClicked={(id) =>
						showDocumentsForm({
							entityId: id,
							isPod: false,
							usage: AttachmentUsageType.Leg,
							onChange: () => response.refetch(),
						})
					}
					onAttachPODClicked={(id) =>
						showDocumentsForm({
							entityId: id,
							usage: AttachmentUsageType.Leg,
							isPod: true,
							onChange: () => response.refetch(),
						})
					}
					onCollectionNoteClicked={(id) =>
						openLegNoteFormDialog({
							legId: id,
							isCollectionNote: true,
						})
					}
					onDeliveryTicketClicked={(id) => openLegNoteFormDialog({ legId: id })}
					onSubcontractorOrderClicked={(id) =>
						toasted(
							jobApi.leg
								.legReportSubcontractorOrderDetail(id, {
									format: "blob",
								})
								.then((x) =>
									window.open(URL.createObjectURL(x.data), "_blank"),
								),
							"Generating subcontractor order",
						)
					}
					onLegNewStatusClicked={(id, newStatus) =>
						toasted(
							jobApi.leg
								.legChangeStatusCreate({
									legId: id,
									status: newStatus,
								})
								.then(() => response.refetch()),
							"Changing leg status",
						)
					}
				/>
			</DGrid>
		</DGrid>
	);
};
