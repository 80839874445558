import { SvgIcon } from "@progress/kendo-react-common";
import type { SelectionRange } from "@progress/kendo-react-dateinputs";
import type {
	TextBoxChangeEvent,
	TextBoxHandle,
} from "@progress/kendo-react-inputs";
import { debounce } from "es-toolkit";
import {
	type Dispatch,
	type SetStateAction,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { closeButtonIcon } from "../../../../../models/src/lib/constants/icon.constants";
import { FilterItemType } from "../../../../../models/src/lib/enums/filter-item-type.enum";
import type {
	DateRangeItem,
	IDateFilter,
	IFilterItem,
} from "../../../../../models/src/lib/interfaces/filter.interface";
import { transformSelectionRange } from "../../../../../utils/src/lib/helpers/date.helpers";
import { onIdFilterChange } from "../../../../../utils/src/lib/helpers/filter.helpers";
import { CelerumSearchInput } from "../celerum-input/celerum-input.component";
import { CelerumTooltip } from "../celerum-tooltip/celerum-tooltip.component";
import styles from "./celerum-filters.module.css";
import { CelerumDropDownWithCheckboxPopup } from "./components/celerum-dropdown-with-checkbox-popup.component";
import { CelerumDropDownWithDateRangePopup } from "./components/celerum-dropdown-with-daterange-popup.component";
import type {
	AllowedFilterItem,
	DateRangeFilterItem,
	DropDownFilterItem,
	FilterValues,
	Filters,
} from "./interfaces/filters.interface";

interface CelerumFiltersProps {
	filters: Filters;
	initialValues: FilterValues;
	// biome-ignore lint/suspicious/noExplicitAny: no thanks
	setFilters: (newFilterState: any) => void;
	clearFilters: () => void;
	setSearchFilter?: Dispatch<SetStateAction<string>>;
}

interface IShowStateObject {
	[key: string]: boolean;
}

export const CelerumFilters = ({
	filters,
	initialValues,
	setFilters,
	clearFilters,
	setSearchFilter,
}: CelerumFiltersProps) => {
	const keys = Object.keys(filters);

	const INITIAL_SHOW_STATE = useMemo(() => {
		const showStateObject: IShowStateObject = {};
		for (const key of keys) {
			showStateObject[`${key}`] = false;
		}

		return showStateObject;
	}, [keys]);

	const [showFilters, setShowFilters] = useState(INITIAL_SHOW_STATE);

	const onFilterChange = useCallback(
		(newFilters: FilterValues) => {
			setFilters(newFilters);
		},
		[setFilters],
	);

	const dropDownClick = (filter: AllowedFilterItem) => {
		setShowFilters((prev: IShowStateObject) => ({
			...INITIAL_SHOW_STATE,
			[filter.name]: !prev[filter.name],
		}));
	};

	const filterItemFactory = (
		key: string,
		initialValues: FilterValues,
		filter: AllowedFilterItem,
	) => {
		const currentFilterValue = initialValues[key] ?? [];
		switch (filter.type) {
			case FilterItemType.DROPDOWN: {
				const castedFilter = filter as DropDownFilterItem;
				return (
					<CelerumDropDownWithCheckboxPopup
						onClick={() => dropDownClick(filter)}
						filtered={!!(currentFilterValue as IFilterItem[]).length}
						filter={castedFilter}
						key={`${castedFilter.name}-${currentFilterValue.length}`}
						filtersCount={
							(currentFilterValue as IFilterItem[])[0]?.value.length || 0
						}
						show={showFilters[castedFilter.name]}
						initialValues={currentFilterValue as IFilterItem[]}
						onClose={() =>
							setShowFilters((prev: IShowStateObject) => ({
								...prev,
								[castedFilter.name]: !prev[castedFilter.name],
							}))
						}
						handleSubmit={(ids: (number | string)[]) => {
							setShowFilters((prev: IShowStateObject) => ({
								...prev,
								[castedFilter.name]: !prev[castedFilter.name],
							}));
							onIdFilterChange(
								castedFilter.column,
								ids,
								currentFilterValue as IFilterItem[],
								filterChange(key),
							);
						}}
					/>
				);
			}
			case FilterItemType.DATERANGE: {
				const castedFilter = filter as DateRangeFilterItem;
				return (
					<CelerumDropDownWithDateRangePopup
						onClick={() => dropDownClick(castedFilter)}
						onClose={() =>
							setShowFilters((prev: IShowStateObject) => ({
								...prev,
								[castedFilter.name]: !prev[castedFilter.name],
							}))
						}
						filter={castedFilter}
						key={`${castedFilter.name}-${currentFilterValue.length}`}
						filtered={!!(currentFilterValue as IDateFilter[]).length}
						show={showFilters[castedFilter.name]}
						initialRange={currentFilterValue as IDateFilter[]}
						handleSubmit={(date: SelectionRange | null) => {
							setShowFilters((prev: IShowStateObject) => ({
								...prev,
								[castedFilter.name]: !prev[castedFilter.name],
							}));
							handleDateChange(castedFilter.column, date, key);
						}}
					/>
				);
			}
		}
	};

	const filterChange = (key: string) => (newFilterItem: IFilterItem[]) =>
		onFilterChange({ ...initialValues, [key]: newFilterItem });

	const onDateChange = useCallback(
		(column: string, value: DateRangeItem, key: string) => {
			const result = (initialValues[key] as IDateFilter[]).filter(
				(date: IDateFilter) => date.id !== column,
			);
			if (value.start || value.end) {
				result.push({ id: column, value });
			}

			onFilterChange({ ...initialValues, [key]: result });
		},
		[initialValues, onFilterChange],
	);

	const handleDateChange = useCallback(
		(column: string, date: SelectionRange | null, key: string) => {
			if (date) {
				const { startDate, endDate } = transformSelectionRange(date);

				onDateChange(
					column,
					{
						start: startDate,
						end: endDate,
					},
					key,
				);
			} else {
				onDateChange(column, { start: undefined, end: undefined }, key);
			}
		},
		[onDateChange],
	);

	const searchInputRef = useRef<TextBoxHandle>(null);

	const handleSearchChange = debounce((e: TextBoxChangeEvent) => {
		setSearchFilter?.(e.value?.toString() || "");
	}, 300);

	return (
		<>
			{Object.entries(filters).map(([key, filter]) =>
				filterItemFactory(key, initialValues, filter),
			)}
			<CelerumTooltip title="Clear Filters">
				<SvgIcon
					width={25}
					icon={closeButtonIcon}
					onClick={clearFilters}
					className={styles.icon}
				/>
			</CelerumTooltip>
			{setSearchFilter && (
				<CelerumSearchInput
					inputRef={searchInputRef}
					setSearchValue={(value: string) => setSearchFilter(value)}
					onChange={(event: TextBoxChangeEvent) => handleSearchChange(event)}
				/>
			)}
		</>
	);
};
